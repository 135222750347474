<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h1>{{ changer }}</h1>
    <h1>{{ wow }}</h1>
    <div class="inputs">
    <!-- <input v-bind="wow" v-on:change="acceptNumber" type="text" maxlength="11" placeholder="wow look at me its a text box"> -->
    <!-- <button v-on:click="buttonChange">Change Message</button> -->
    </div>
    <!-- <input v-model="huh" v-on:change="phoneFormat(this.huh)"> -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data: function() {
    return{
    changer: 'a message',
    wow: ''
    }
  },
  props: {
    msg: String,
    // huh: String
  },
  // TODO: Figure out how vmodel works vs having computed components, change the phone number thing
  methods:{
    // messageChanger: function(){
    //   this.changer = this.wow
    // },
    // acceptNumber() {
    //   var x = this.wow.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    //   this.wow   = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    // }
  //   buttonChange: function() {
  //     this.msg = this.wow
  //   },
  //   phoneFormat: function(phoneNumber){
  //     var cleaned = ('' + phoneNumber).replace(/\D/g, '')
  //   var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  //   if (match) {
  //     return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  //   }
  // return null
  //   }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
inputs{
  
}
</style>
